









































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useUser from "@/use/user";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  setup(props, { root, emit }) {
    const { getRoleName } = useUser({ root });

    const entries = ref<any[]>([]);
    const isLoading = ref(false);
    const model = ref<any>(null);
    const search = ref(null);

    const fields = computed(() => {
      if (!model.value) return [];

      return Object.keys(model.value as any).map((key) => ({
        key,
        value: (model.value as any)[key] || "n/a",
      }));
    });

    const items = computed(() => entries.value);

    watch(
      () => search.value,
      (val) => {
        if (isLoading.value) return;

        if (val) {
          isLoading.value = true;

          const axiosInstance = root.$store.getters[
            "api/getInstance"
          ] as AxiosInstance;

          axiosInstance
            .get("user", { params: { search: val } })
            .then(({ data }) => {
              const { users } = data;

              const ids = new Set(entries.value.map((e) => e.id));
              entries.value = [
                ...entries.value,
                ...users.filter((u: any) => !ids.has(u.id)),
              ];
            })
            .catch((error) => console.log(error))
            .finally(() => (isLoading.value = false));
        }
      }
    );

    watch(
      () => model.value,
      (val) => emit("input", val)
    );

    return { entries, isLoading, model, search, fields, items, getRoleName };
  },
});
